import { EMAIL_VALIDATION, UK_POSTCODE_REGEX } from '@core/Constants/Validations';

import { InputItem } from 'Components/FormFields/FormFields.entity';

import { defaultErrorMessage } from '../Utils/ErrorMessages';
import { isValidPhoneNumber } from '../Utils/Leadforms.util';

const RequestVideoFieldItems: InputItem[] = [
  {
    id: 'firstName',
    dataTestId: 'text',
    type: 'input',
    autoComplete: 'on',
    label: ({ firstNameLabel }) => firstNameLabel,
    isValid: ({ value }) => value?.length > 2,
    isActive: () => true,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'lastName',
    dataTestId: 'text',
    type: 'input',
    autoComplete: 'on',
    label: ({ lastNameLabel }) => lastNameLabel,
    isValid: ({ value }) => value?.length > 2,
    isActive: () => true,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'email',
    dataTestId: 'text',
    type: 'input',
    label: ({ emailAddressLabel }) => emailAddressLabel,
    isValid: ({ value }) => EMAIL_VALIDATION.test(value),
    isActive: () => true,
    width: ({ flags }) => (flags.postcodeRequired ? 'full' : 'half'),
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'phone',
    dataTestId: 'text',
    type: 'input',
    label: ({ phoneNumberLabel }) => phoneNumberLabel,
    isValid: ({ value }) => isValidPhoneNumber(value),
    isActive: () => true,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'postcode',
    dataTestId: 'text',
    type: 'input',
    label: ({ postalCodeLabel }) => postalCodeLabel,
    isValid: ({ value }) => UK_POSTCODE_REGEX.test(value),
    isActive: ({ flags }) => !!flags.postcodeRequired,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
    defaultValue: ({ extraData }) => extraData?.postcode,
  },
  {
    id: 'message',
    dataTestId: 'text',
    type: 'textarea',
    label: ({ optionalMessageLabel }) => optionalMessageLabel,
    width: () => 'full',
    isActive: () => true,
  },
  {
    id: 'marketingOptIns',
    defaultValue: () => false,
    isActive: () => true,
  },
];

export { RequestVideoFieldItems };
