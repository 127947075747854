import { useEffect, useState } from 'react';

import Checkbox from '@gds/Checkbox/Checkbox';
import { Typography } from '@gds/Typography/Typography';

import styles from './AutomaticCallbackPreference.module.css';

const AutoCallbackPreference = ({
  values,
  handleChange,
  isMsgLeadAutoCallbackExp,
  errors,
  strings,
}: {
  values: Record<string, any>;
  handleChange: (field: string, value: boolean) => void;
  isMsgLeadAutoCallbackExp: boolean;
  errors: Record<string, any>;
  strings: Record<string, string>;
}) => {
  // Local state to track if any checkbox is checked
  const [isAnyChecked, setIsAnyChecked] = useState(false);

  // Update isAnyChecked based on the checkbox values
  useEffect(() => {
    setIsAnyChecked(values.callBackInstantlyChkBox || values.callBackDuringDayChkBox);
  }, [values.callBackInstantlyChkBox, values.callBackDuringDayChkBox]);

  const isError =
    (errors['callBackInstantlyChkBox'] || errors['callBackDuringDayChkBox']) && !isAnyChecked;

  return (
    isMsgLeadAutoCallbackExp && (
      <div className={`${styles.autoCallbackChkboxWrapper} ${isError ? styles.error : ''}`}>
        <Typography variant="h6">{strings.automaticCallBackPrefHeader}</Typography>

        <div className={styles.field}>
          <label htmlFor="callback-instantly" aria-labelledby="callback-instantly">
            <Checkbox
              id="callback-instantly"
              checked={values.callBackInstantlyChkBox}
              onChange={() => null}
              onClick={() => {
                handleChange('callBackInstantlyChkBox', !values.callBackInstantlyChkBox);
                handleChange('callBackDuringDayChkBox', false);
              }}
            />
            <Typography variant="body3" Component="span">
              {strings.automaticCallBackPrefOption1}
            </Typography>
          </label>
        </div>

        <div className={styles.field}>
          <label htmlFor="callback-during-day" aria-labelledby="callback-during-day">
            <Checkbox
              id="callback-during-day"
              checked={values.callBackDuringDayChkBox}
              onChange={() => null}
              onClick={() => {
                handleChange('callBackDuringDayChkBox', !values.callBackDuringDayChkBox);
                handleChange('callBackInstantlyChkBox', false);
              }}
            />
            <Typography variant="body3" Component="span">
              {strings.automaticCallBackPrefOption2}
            </Typography>
          </label>
        </div>
        {isError && (
          <span className={styles.errorMessage}>
            {errors['callBackInstantlyChkBox'] || errors['callBackDuringDayChkBox']}
          </span>
        )}
      </div>
    )
  );
};

export default AutoCallbackPreference;
