import { asMessages } from '@core/Utils/Intl/AsMessages';

const definition = asMessages('containers.pdp', {
  buyOnline: 'Buy online',
  buy: 'Buy',
  enquireOnline: 'Enquire',
  pageTitle: `{stockCondition} {make} {model} for sale - {variant}`,
  pageDescription: `{stockCondition} {year} {prettyNameLong} for sale for {price}. Sold by {dealer} in {town}, {country}.`,
  messageDealerButton: 'Message dealer',
  requestCallbackButton: 'Request callback',
  requestVideoButton: 'Request video',
  requestImagesButton: 'Request images',
  bookAppointmentButton: 'Book appointment',
  leasingOnlineButton: 'Message us',
  leasingOnlineBody:
    'Enter your details below & one of our team will be in touch to talk through your leasing options.',
  leasingOnlineButtonHeader: 'Message us',
  reservedVehicleLabel: 'Vehicle reserved',
  inActiveVehicleLabel: 'Vehicle unavailable',
  callDealerButton: 'Call dealer',
  pdpCampaignTitle: 'Our finance deals',
  historyCheckButton: 'Receive the report',
  buyOnlineWith: 'powered by',
  viewReport: 'View report',
  leadsReCaptcha:
    'This site is protected by reCAPTCHA and the Google <privacyPolicy>{privacyPolicyLabel} </privacyPolicy> and <termsOfService>{label}</termsOfService> apply.',
  homeDelivery: 'Home delivery',
  campaignLayoutOne: '2 offers',
  campaignLayoutOneTwo: ' on this car when you buy online.',
  campaignLayoutThree: ' Find out more',
  buyOnlineHeader: 'Buying a car online with heycar',
  callDealerError:
    'Sorry, an error occurred when retrieving the phone number. Please request a call back instead.',
  reserveNow: 'Reserve now',
  reserve: 'Reserve',
  message: 'Message',
  call: 'Call',
  hurryUpPillMobileText: 'Hurry up!', // max characters 35
  hurryUpPillText: 'Hurry up! This car will be taken off soon',
  investorGeneric: '£500 cashback',
  investorPromo: 'Hassle-free delivery',
  ecomGeneric: '£300 cashback',
  ecomPromo: 'Hassle-free delivery',
  leasingText: 'Leasing',
  filteredPlpBrdcmbText: 'Back to search',
  unFilteredPlpBrdcmbText: 'See all cars',
  makeModelPlpBrdcmbText: 'View all {make} {model}',
  messageUs: 'Message us',
  leasingUrl: 'https://share.hsforms.com/1tCDYYr7gSyW01Ubk7NuV0g3n1ib',
  leasingCallUs: '020 3936 1257',
  leasingCallText: 'Call us',
  scheduleCallBack: 'Programmer mon appel',
} as const);

export const pdpMessages = definition.messages;
export const defaultPdpMessages = definition.defaultMessages;
export type PdpMessages = typeof definition.messages;
export type PdPMessagesKeys = keyof PdpMessages;
