import { aggregationsAtom } from '@core/Atoms/Search/AggregationsAtom';
import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { getMakeModelLabelsFromAggregationsArray } from '@core/Utils/MakeModel/GetMakeModelLabelsFromAggregations';
import Checkbox from '@gds/Checkbox/Checkbox';
import { Info } from '@gds/Icons/Paths/Info';
import Tooltip from '@gds/Tooltip/Tooltip';
import { Typography } from '@gds/Typography/Typography';
import { useAtomValue } from 'jotai';

import { useInitAggregations } from 'Hooks/UseInitAggregations';

import { DRCheckboxProps } from '../MultiLeadCheckboxes/MultiLeadCheckboxes.entity';

import { PrivacyPolicyLink } from './PrivacyPolicyLink/PrivacyPolicyLink';
import { useDriftrockCheckbox } from './UseDriftrockCheckbox';

import styles from '../OneClickCheckbox/OneClickCheckbox.module.css';

export const ACTIONS = {
  TEST_DRIVE: 'schedule a test drive',
};

export const DriftrockCheckbox = ({ inputProps, isChecked }: DRCheckboxProps) => {
  const currentVehicles = useAtomValue(currentVehicleAtom);
  useInitAggregations('uk');
  const aggregations = useAtomValue(aggregationsAtom);
  const product = useDriftrockCheckbox({ vehicle: currentVehicles, locale: 'uk' });

  if (!product) return null;

  const { id: driftrockChkBoxId, handleChange } = inputProps;

  const actionStr = ACTIONS[product.leadType as keyof typeof ACTIONS] || '';

  const { make, model } = getMakeModelLabelsFromAggregationsArray(
    product.productTarget.make,
    product.productTarget.model,
    aggregations,
  );

  const infoPopOverText = `Your information will be shared with ${make} in accordance with their privacy policy`;

  return (
    <label
      htmlFor="driftrock-similarcars"
      aria-labelledby="driftrock-similarcars"
      className={styles.oneClickCheckbox}
    >
      <Checkbox
        id="driftrock-similarcars"
        checked={isChecked}
        onChange={() => null}
        onClick={() => handleChange(driftrockChkBoxId, !isChecked)}
      />
      <Typography variant="subheading4" Component="span">
        {`I would like to ${actionStr} for a brand new ${make} ${model} as it matches the vehicle I am enquiring about or it matches its body style or fuel type`}
        <Tooltip
          className={styles.tooltip}
          dialogId="driftrock-chkbk-popover"
          extraText={<PrivacyPolicyLink currentBrand={make} />}
          text={infoPopOverText}
        >
          <Info fontSize={24} />
        </Tooltip>
      </Typography>
    </label>
  );
};
