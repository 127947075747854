import { getFormattedCurrency } from '@core/Utils/Intl/GetFormattedCurrency';

import { calculationAtom } from '@fincalc/Atoms/Calculation.atom';
import { Accordion } from '@gds/Accordion/Accordion';
import { Checkbox } from '@gds/Checkbox/Checkbox';
import Grid from '@gds/Grid/Grid';
import { Typography } from '@gds/Typography/Typography';
import { useAtomValue } from 'jotai';

import { leadFormSubmitAttemptedAtom } from 'Atoms/App/Pdp/leadFormSubmitAttempted.atom';

import { AccordionCheckbox } from '../../AccordionCheckbox/AccordionCheckbox';
import { FinanceQuoteProps } from '../Enrichment.entity';

import styles from './FinanceQuote.module.css';

export const FinanceQuote = ({
  strings,
  inputProps,
  locale,
  shouldShowCheckboxToggle,
  id,
}: FinanceQuoteProps) => {
  const {
    financeQuoteAccordionHeader: accordionHeader,
    financeQuoteCheckbox: checkboxText,
    financeQuoteDeposit: deposit,
    financeQuoteApr: apr,
    financeQuoteTerm: term,
    financeQuoteMileage: mileage,
    financeQuoteMonth: month,
    financeQuoteFinalPayment: optFinalPayment,
    financeQuoteDepContribution: depContribution,
    enrichmentTextHeaderClose: headerClose,
    enrichmentTextHeaderOpen: headerOpen,
    financeQuoteInsurance: insurance,
    financeQuoteRenaultTcs: renaultTcs,
    financeQuoteVwfsTcs: vwfsTcs,
    financeQuoteCheckboxMonthly: financeQuoteCheckboxMonthly,
  } = strings;
  const {
    ids,
    handleChange,
    values: { isFinanceQuoteChecked, isFinanceQuoteTcsChecked },
  } = inputProps;

  const leadFormSubmitAttempted = useAtomValue(leadFormSubmitAttemptedAtom);

  const financeQuoteId = ids.find((field: string) => field === 'financeQuoteChkBox');
  const financeQuoteTcsId = ids.find((field: string) => field === 'financeQuoteTcsChkBox');

  const customToggleTextHeader = {
    openText: headerOpen,
    closeText: headerClose,
  };

  const handleFinanceQuoteChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    handleChange(financeQuoteId, event.target.checked);
    const isChecked = event.target.checked;
    !isChecked && handleChange(financeQuoteTcsId, event.target.checked);
  };

  const handleFinanceQuoteTcsChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    handleChange(financeQuoteTcsId, event.target.checked);
  };
  const calculation = useAtomValue(calculationAtom);

  const depositContribution =
    calculation?.products?.[calculation.selectedProduct].calculationResult.totalContribution;

  const isDepositContribution = depositContribution !== undefined && depositContribution > 0;
  const isCaptives =
    calculation?.captiveId === 'rb_fr' ||
    calculation?.captiveId === 'vwfs_fr' ||
    calculation?.captiveId === 'cgi_fr';

  const isStandardFinancing = calculation?.selectedProduct === 'standardFinancing';

  const content = (
    <Grid.Row className={styles.financeQuoteWrapper}>
      <Grid.Col width={{ tablet: 12, mobile: 12 }}>
        <div>
          <div className={styles.checkboxQuoteContainer}>
            <div className={styles.checkboxContainer}>
              <Checkbox
                checked={isFinanceQuoteChecked as boolean}
                onChange={handleFinanceQuoteChange}
                id={id}
              />
              <Typography variant="body3">{checkboxText}</Typography>
            </div>
            <Typography variant="h6" className={styles.quoteText}>
              {getFormattedCurrency(
                calculation?.products?.[calculation.selectedProduct].calculationResult
                  .totalMonthlyRate,
                locale,
              )}
              {financeQuoteCheckboxMonthly}
              {locale === 'uk' && <span>{calculation?.selectedProduct}</span>}
            </Typography>
          </div>
          {locale === 'uk' && (
            <div className={styles.financeInformationContainer}>
              <div className={styles.genericFinanceDetails}>
                <div className={styles.financeInformation}>
                  <Typography variant="subheading1">{deposit}:</Typography>
                  <Typography variant="body4" className={styles.bodyText}>
                    {getFormattedCurrency(
                      calculation?.products?.[calculation.selectedProduct].calculationResult
                        .cashDeposit,
                      locale,
                    )}
                  </Typography>
                </div>
                {isDepositContribution && (
                  <div className={styles.financeInformation}>
                    <Typography variant="subheading1">{depContribution}:</Typography>
                    <Typography variant="body4" className={styles.bodyText}>
                      {getFormattedCurrency(depositContribution, locale)}{' '}
                    </Typography>
                  </div>
                )}
                <div className={styles.financeInformation}>
                  <Typography variant="subheading1">{apr}:</Typography>
                  <Typography variant="body4" className={styles.bodyText}>
                    {
                      calculation?.products?.[calculation.selectedProduct].calculationResult
                        .annualInterestRate
                    }
                    %
                  </Typography>
                </div>
                <div className={styles.financeInformation}>
                  <Typography variant="subheading1">{term}:</Typography>
                  <Typography variant="body4" className={styles.bodyText}>
                    {calculation?.products?.[calculation.selectedProduct].inputs.months.value}{' '}
                    {month}
                  </Typography>
                </div>
              </div>
              {calculation?.selectedProduct === 'PCP' && (
                <div className={styles.pcpFinanceDetails}>
                  <div className={styles.financeInformation}>
                    <Typography variant="subheading1">{mileage}:</Typography>
                    <Typography variant="body4" className={styles.bodyText}>
                      {calculation?.products?.[calculation.selectedProduct].inputs.mileage.value}
                    </Typography>
                  </div>
                  <div className={styles.financeInformation}>
                    <Typography variant="subheading1">{optFinalPayment}:</Typography>
                    <Typography variant="body4" className={styles.bodyText}>
                      {getFormattedCurrency(
                        calculation?.products?.[calculation.selectedProduct].calculationResult
                          .optionalFinalPayment,
                        locale,
                      )}
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          )}
          {locale === 'fr' && (
            <div className={styles.financeInformationContainer}>
              <div className={styles.genericFinanceDetails}>
                <div className={styles.financeInformation}>
                  <Typography variant="subheading1">{deposit}:</Typography>
                  <Typography variant="body4" className={styles.bodyText}>
                    {getFormattedCurrency(
                      calculation?.products?.[calculation.selectedProduct]?.inputs?.deposit?.value,
                      locale,
                    )}
                  </Typography>
                </div>
                <div className={styles.financeInformation}>
                  <Typography variant="subheading1">{term}:</Typography>
                  <Typography variant="body4" className={styles.bodyText}>
                    {calculation?.products?.[calculation.selectedProduct].inputs.months.value}{' '}
                    {month}
                  </Typography>
                </div>
                {isStandardFinancing && (
                  <div className={styles.financeInformation}>
                    <Typography variant="subheading1">{apr}:</Typography>
                    <Typography variant="body4" className={styles.bodyText}>
                      {
                        calculation?.products?.[calculation.selectedProduct].calculationResult
                          .annualInterestRate
                      }
                      %
                    </Typography>
                  </div>
                )}
                <div className={styles.financeInformation}>
                  <Typography variant="subheading1">{insurance}:</Typography>
                  <Typography variant="body4" className={styles.bodyText}>
                    {calculation?.products?.[calculation.selectedProduct]?.additionalServices?.[0]
                      ?.userSelected ||
                    calculation?.products?.[calculation.selectedProduct]?.additionalServices?.[1]
                      ?.userSelected
                      ? 'Oui'
                      : 'Non'}
                  </Typography>
                </div>
              </div>
              {isCaptives && (
                <div className={styles.checkboxQuoteTcsContainer}>
                  <div className={styles.checkboxTcsContainer}>
                    <Checkbox
                      checked={isFinanceQuoteTcsChecked as boolean}
                      disabled={!isFinanceQuoteChecked as boolean}
                      error={
                        leadFormSubmitAttempted &&
                        !isFinanceQuoteTcsChecked &&
                        isFinanceQuoteChecked
                      }
                      onChange={handleFinanceQuoteTcsChange}
                    />
                    {(calculation.captiveId === 'rb_fr' || calculation.captiveId === 'cgi_fr') && (
                      <Typography
                        variant="body4"
                        className={`${!isFinanceQuoteChecked && styles.disabled} `}
                      >
                        {renaultTcs}
                      </Typography>
                    )}
                    {calculation.captiveId === 'vwfs_fr' && (
                      <Typography
                        variant="body4"
                        className={`${!isFinanceQuoteChecked && styles.disabled} `}
                      >
                        {vwfsTcs}
                      </Typography>
                    )}
                  </div>
                  {leadFormSubmitAttempted &&
                    !isFinanceQuoteTcsChecked &&
                    isFinanceQuoteChecked && <span className={styles.errorMessage}>Requis</span>}
                </div>
              )}
            </div>
          )}
        </div>
      </Grid.Col>
    </Grid.Row>
  );

  return (
    <>
      {calculation &&
        (shouldShowCheckboxToggle ? (
          <AccordionCheckbox title={accordionHeader} id={id}>
            {content}
          </AccordionCheckbox>
        ) : (
          <Accordion
            title={accordionHeader}
            customtoggletextheader={customToggleTextHeader}
            open={locale === 'fr'}
          >
            {content}
          </Accordion>
        ))}
    </>
  );
};
