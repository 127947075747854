import { AllExperiments } from '@growthBookExperimentation/Entities/Experiments.entities';
import { FlagKey } from '@growthBookExperimentation/Entities/FlagKeys.entities';
import { getExperimentViewedEvents } from '@tracking/Utils/GetExperimentViewedEvents';
import { callStartToEvent } from '../../../tracking/src/Schemas/CallStart/CallStartToEvent';
import { callSubmitToEvent } from '../../../tracking/src/Schemas/CallSubmit/CallSubmitToEvent';
import { leadStartToEvent } from '../../../tracking/src/Schemas/Lead/LeadStart/LeadStartToEvent';
import { leadSubmitToEvent } from '../../../tracking/src/Schemas/Lead/LeadSubmit/LeadSubmitToEvent';
import { leadToEvent } from '../../../tracking/src/Schemas/Lead/LeadToEvent';
import { vehicleToListingEvent } from '../../../tracking/src/Schemas/Listing/VehicleToListingEvent';
import { vehicleToListingFeatureEvent } from '../../../tracking/src/Schemas/ListingFeature/VehicleToListingFeatureEvent';
import { marketingToEvent } from '../../../tracking/src/Schemas/Marketing/MarketingToEvent';
import { userContextToEvent } from '../../../tracking/src/Schemas/UserContext/UserContextToEvent';
import { LeadFormState } from '../Atoms/Pdp/FormState.atom';
import { SearchParamsType } from '../Atoms/SearchParams/SearchParams.atom';
import { TrackingDataType } from '../Entities/Tracking/Tracking.entity';
import { Vehicle } from '../Entities/Vehicle/Vehicle.entity';

interface Props {
  searchParams: SearchParamsType;
  userId: string | null | undefined;
  formState: LeadFormState;
  leadId: string;
  leadFormId: string;
  listingId: string | null;
  captiveId?: string;
  source?: string;
  vehicle: Vehicle;
  experiments?: AllExperiments;
  ctaLocation: string;
  leadAction: string;
  hasOneClick?: boolean;
  numSimilarVehicles?: number;
}

export function generateLeadTrackingData({
  searchParams,
  userId,
  formState,
  leadId,
  leadFormId,
  listingId,
  captiveId,
  source,
  vehicle,
  experiments,
  ctaLocation,
  leadAction,
  hasOneClick,
  numSimilarVehicles,
}: Props): Record<string, TrackingDataType> {
  const hasSimilarVehicles = Boolean(numSimilarVehicles);
  const marketingDataObj = {
    marketingValues: { ...(searchParams ?? {}) },
  };

  const userData = {
    userId,
    values: { ...formState },
  };

  // should send these boolean to listing feature schema only for lead form submit event
  const extraData =
    leadAction === 'submit'
      ? {
          has_oneClick_chkBox:
            hasOneClick &&
            hasSimilarVehicles &&
            !vehicle.eligibleProducts.some(product => product.includes('DRIFTROCK_')),
          is_oneclick_chkBox_checked: formState.oneClickChkBox,
          num_similarVehicles: numSimilarVehicles,
          has_driftrock_chkBox: Object.keys(formState).includes('oneClickDriftrockChkBox'),
          is_driftrock_chkBox_checked: formState.oneClickDriftrockChkBox,
        }
      : undefined;
  const leadSubmitEventObj = {
    listingId: listingId as string,
    leadId,
    leadType: leadFormId as string,
    captiveId: captiveId as string,
  };
  const leadStartObj = {
    listingId,
    leadType: leadFormId as string,
    ctaLocation,
  };

  const leadEventObj = {
    vehicle,
    values: { ...formState },
    leadType: leadFormId as string,
    leadId,
    source,
  };
  // event actions
  const leadSubmitEvent = leadSubmitToEvent(leadSubmitEventObj);
  const leadStartEvent = leadStartToEvent(leadStartObj);
  const callStartEvent = callStartToEvent(leadStartObj);

  // event entities
  const userEvent = userContextToEvent(userData);
  const listingEvent = vehicleToListingEvent(vehicle);
  const marketingEvent = marketingToEvent(marketingDataObj);
  const listingFeatureEvent = vehicleToListingFeatureEvent(vehicle, extraData);
  const leadEvent = leadToEvent(leadEventObj);

  const selectedLeadFormExperimentIds: FlagKey[] = [
    'message-field-leadform-experiment',
    'uk-enrichment-leadform-experiment',
    'name-field-leadform-experiment',
  ];

  const selectedExperimentViewedEvents = getExperimentViewedEvents(
    selectedLeadFormExperimentIds,
    experiments,
  );

  const contextStart = [
    listingEvent,
    listingFeatureEvent,
    userEvent,
    marketingEvent,

    ...((leadFormId === 'message-dealer' || leadFormId === 'request-callback') &&
    selectedExperimentViewedEvents.length > 0
      ? [...selectedExperimentViewedEvents]
      : []),
  ];

  const contextSubmit = [leadEvent, listingEvent, listingFeatureEvent, userEvent, marketingEvent];
  return {
    leadStart: { event: leadStartEvent, context: contextStart },
    leadSubmit: { event: leadSubmitEvent, context: contextSubmit },
    callStart: { event: callStartEvent, context: contextStart },
    callSubmit: { context: contextSubmit } as any,
  };
}

export const createEventWithCallNumber = (
  vehicleId: string,
  callNumber: string,
  ctaLocation: string,
) => {
  const leadSubmitEventObj = {
    listingId: vehicleId,
    leadId: '',
    leadType: 'call-dealer',
    callNumber: callNumber,
    ctaLocation: ctaLocation,
  };
  const callSubmitEvent = callSubmitToEvent(leadSubmitEventObj);
  return callSubmitEvent;
};
