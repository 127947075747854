import { DialogPos, PositionDetails } from './Tooltip.entities';

const DIALOG_OFFSET = 8;
const DIALOG_MAX_WIDTH = 288;
const DEFAULT_POS = 'bottom';

const getPosParts = (position: DialogPos) => {
  const parts = position.split('-');
  return { targetSide: parts[0], contentFlow: parts[1] };
};

const calcCoordsforPos = (element: HTMLButtonElement, position: DialogPos): PositionDetails => {
  const targetBounds = element.getBoundingClientRect();
  const { x, y, width: w, height: h } = targetBounds;
  const { targetSide, contentFlow } = getPosParts(position);
  const pageX = x;
  const pageY = y;
  let dialogX = pageX;
  let dialogY = pageY;

  if (targetSide === 'top' || targetSide === 'bottom') {
    dialogY = targetSide === 'top' ? pageY - DIALOG_OFFSET : pageY + h + DIALOG_OFFSET;

    if (!contentFlow) {
      dialogX = pageX + w / 2;
    } else if (contentFlow === 'right') {
      dialogX = pageX;
    } else if (contentFlow === 'left') {
      dialogX = pageX + w;
    }
  }

  if (targetSide === 'left' || targetSide === 'right') {
    dialogX = targetSide === 'left' ? pageX - DIALOG_OFFSET : pageX + w + DIALOG_OFFSET;

    if (!contentFlow) {
      dialogY = pageY + h / 2;
    } else if (contentFlow === 'top') {
      dialogY = pageY + h;
    } else if (contentFlow === 'bottom') {
      dialogY = pageY;
    }
  }

  return { coords: [dialogX, dialogY], name: position };
};

//NOTE: at the moment we can only check left and right bounds
//Because we know that max width of the dialog, but as there is no max height
//and is doesn't exist in the dom at this point, we cant know the height
const willDialogFitInVP = (posDetails: PositionDetails) => {
  const { targetSide, contentFlow } = getPosParts(posDetails.name);
  const [x] = posDetails.coords;
  const DIALOG_HALF_WIDTH = DIALOG_MAX_WIDTH / 2;
  const isLeftFacing = targetSide === 'left' || contentFlow === 'left';
  const isRightFacing = targetSide === 'right' || contentFlow === 'right';

  if (
    (isLeftFacing && x - DIALOG_MAX_WIDTH < 0) ||
    (isRightFacing && x + DIALOG_MAX_WIDTH > window.innerWidth)
  ) {
    return false;
  }

  if (
    !contentFlow &&
    (targetSide === 'top' || targetSide === 'bottom') &&
    (x - DIALOG_HALF_WIDTH < 0 || x + DIALOG_HALF_WIDTH > window.innerWidth)
  ) {
    return false;
  }

  return true;
};

//if the requested position wont fit in the viewport, try the next position along horizontally
const roundRobinPos = (position: DialogPos): DialogPos => {
  if (!position) return DEFAULT_POS;
  const { targetSide, contentFlow } = getPosParts(position);

  if (targetSide === 'top' || targetSide === 'bottom') {
    if (contentFlow === 'left') {
      return targetSide;
    }
    if (!contentFlow) {
      return `${targetSide}-right`;
    }
    if (contentFlow === 'right') {
      return `${targetSide}-left`;
    }
  }

  if (targetSide === 'right') {
    if (!contentFlow) return 'left';
    return `left-${contentFlow}` as DialogPos;
  }

  if (targetSide === 'left') {
    if (contentFlow === 'top') return 'top';
    return 'bottom';
  }

  return DEFAULT_POS;
};

const getDialogCoords = (element: HTMLButtonElement, requestedPosition: DialogPos) => {
  let posDetails = calcCoordsforPos(element, requestedPosition);
  let isValid = willDialogFitInVP(posDetails);
  let attempts = 0;
  let testPosition = roundRobinPos(requestedPosition);

  //we test it 2 times, so that all the possible round robin positions of a given position are tested
  //and if they are all false we fallback to the requested position
  while (!isValid && attempts < 2) {
    const testPosDetails = calcCoordsforPos(element, testPosition);
    isValid = willDialogFitInVP(testPosDetails);

    if (isValid) {
      posDetails = testPosDetails;
    } else {
      testPosition = roundRobinPos(testPosition);
      attempts++;
    }
  }

  return posDetails;
};

export { calcCoordsforPos, getDialogCoords, getPosParts, roundRobinPos, willDialogFitInVP };
