import { getClientSideCookieValue } from '@cookies/GetClientSideCookieValue';
import { LOCALE } from '@core/Constants/Locale.constants';
import {
  FR_PHONE_VALIDATION,
  UK_PHONE_VALIDATION,
  DE_PHONE_VALIDATION,
} from '@core/Constants/Validations';
import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';

export const checkMinDate = (value: string) => {
  const dateValue = Number.isNaN(Date.parse(value))
    ? value
    : new Date(value).toISOString?.().split('T')[0];

  const minDateValue = new Date().toISOString?.().split('T')[0];
  const date = new Date(dateValue).getTime();
  const min = new Date(minDateValue).getTime();

  return date >= min;
};

export const checkMaxDate = (value: string) => {
  const dateValue = Number.isNaN(Date.parse(value))
    ? value
    : new Date(value).toISOString?.().split('T')[0];

  const today = new Date();
  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() + 7);

  const date = new Date(dateValue).getTime();
  const max = new Date(maxDate.toISOString().split('T')[0]).getTime();
  return date <= max;
};

export const checkDealerOpenOnDate = (value?: string, vehicle?: Vehicle): boolean => {
  if (!vehicle || !vehicle.dealer || !vehicle.dealer.openingTimes || !value) {
    return true;
  }

  const date = new Date(value);
  const now = new Date();
  const dayOfWeek = date.getUTCDay();

  const openingTimes = vehicle.dealer.openingTimes;
  const openingTimesForDay = openingTimes.filter(
    openingTime => openingTime.dayOfWeek === dayOfWeek,
  );

  if (!openingTimesForDay.length) {
    return false;
  }

  if (date.toDateString() === now.toDateString()) {
    // Get the latest closing hour for today
    const latestClosingHour = openingTimesForDay
      .map(openingTime => {
        const [hours, minutes] = openingTime.openTo.split(':').map(Number);
        return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes).getTime();
      })
      .reduce((latest, current) => (current > latest ? current : latest), -Infinity);

    // Check if the current time is after the latest closing hour
    const currentTime = now.getTime();

    if (currentTime > latestClosingHour) {
      return false;
    }
  }

  return true;
};

export const leadModalHeaderText = (leadType: string, messages: Record<string, string>): string => {
  const {
    messageDealerButton,
    requestCallbackButton,
    requestVideoButton,
    requestImagesButton,
    bookAppointmentButton,
    leasingOnlineButtonHeader,
  } = messages;
  const headerText: Record<string, string> = {
    'message-dealer': messageDealerButton,
    'message-dealer-finance': messageDealerButton,
    'request-callback': requestCallbackButton,
    'request-video': requestVideoButton,
    'request-images': requestImagesButton,
    'book-appointment': bookAppointmentButton,
    'leasing-online': leasingOnlineButtonHeader,
  };
  return headerText[leadType];
};

export const isValidPhoneNumber = (value: string): boolean => {
  const locale = getClientSideCookieValue(LOCALE);
  if (locale === 'de') {
    return DE_PHONE_VALIDATION.test(value);
  }
  if (locale === 'fr') {
    return FR_PHONE_VALIDATION.test(value);
  }
  return UK_PHONE_VALIDATION.test(value);
};
