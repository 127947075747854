/* istanbul ignore file */
import { useRef } from 'react';

import { localeAtom } from '@core/Atoms/Locale/Locale.atom';
import { formErrorsAtom } from '@core/Atoms/Pdp/FormErrors.atom';
import { LeadFormState, formStateAtom } from '@core/Atoms/Pdp/FormState.atom';

import { formTouchedAtom } from '@core/Atoms/Pdp/FormTouched.atom';
import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';
import { useAtom, useAtomValue } from 'jotai';

import { leadFormFieldsAtom } from 'Atoms/App/Pdp/LeadFormFields.atom';
import { leadFormIdAtom } from 'Atoms/App/Pdp/LeadFormId.atom';
import { FieldMessage } from 'Components/FormFields/FormFields.entity';

const cgiFormIds = ['message-dealer', 'request-callback'];

export const useFormState = (strings: FieldMessage) => {
  const fields = useAtomValue(leadFormFieldsAtom);
  const [values, setValues] = useAtom(formStateAtom);
  const vehicle = useAtomValue(currentVehicleAtom)!;
  const leadId = useAtomValue(leadFormIdAtom);

  const [touched, setTouched] = useAtom(formTouchedAtom);
  const [errors, setErrors] = useAtom(formErrorsAtom);
  const locale = useAtomValue(localeAtom);
  const flags = useAtomValue(flagsAtom);

  const inputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});
  const inputOrder = fields.map(item => item.id);
  const baseFields: (keyof LeadFormState)[] = [
    'firstName',
    'lastName',
    'fullName',
    'phone',
    'email',
  ];

  if (leadId === 'request-callback') {
    baseFields.push('date', flags.kaisaCallback ? 'kaisaTime' : 'preferredTime');
  }

  if (leadId === 'book-appointment') {
    baseFields.push('date', 'preferredTime');
  }

  if (
    cgiFormIds.includes(leadId) &&
    locale === 'fr' &&
    flags.cgiEligible &&
    vehicle.dealer.fdlConditions === 'cgi_fr'
  ) {
    baseFields.push('title');
  }

  const requiredFields: (keyof LeadFormState)[] =
    locale === 'uk' ? [...baseFields, 'postcode'] : baseFields;

  const handleChange = (field: string, value: string | boolean) => {
    setValues(prevValues => ({ ...prevValues, [field]: value }));
    // Checkboxes for instant and during day callback for FR
    if (field === 'callBackInstantlyChkBox' || field === 'callBackDuringDayChkBox') {
      setErrors(prevErrors => {
        return {
          ...prevErrors,
          callBackInstantlyChkBox: null,
          callBackDuringDayChkBox: null,
        };
      });
    }
  };

  const handleBlur = (field: string) => {
    setTouched(prevTouched => ({ ...prevTouched, [field]: true }));

    const fieldConfig = fields.find(item => item.id === field);
    if (fieldConfig?.isValid) {
      const isValid = fieldConfig.isValid({
        value: values[field as keyof LeadFormState],
        vehicle: vehicle,
      });
      setErrors(prevErrors => {
        const errorMessage = fieldConfig.errorMessage
          ? fieldConfig.errorMessage({
              messages: strings as any,
              value: values[field as keyof LeadFormState],
            })
          : '';

        return {
          ...prevErrors,
          [field]: isValid ? null : errorMessage,
        };
      });
    }
  };

  const handleKeyDown = (
    field: string,
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const currentIndex = inputOrder.indexOf(field);
      if (currentIndex !== -1 && currentIndex < inputOrder.length - 1) {
        const nextField = inputOrder[currentIndex + 1];
        inputRefs.current[nextField]?.focus();
      }
    }
  };

  const handleSubmitValidation = () => {
    const newErrors: Partial<Record<string, any>> = {};

    fields.forEach(item => {
      const isRequired = requiredFields.includes(item.id as keyof LeadFormState);

      if (!values[item.id as keyof LeadFormState] && isRequired) {
        newErrors[item.id] = item.errorMessage
          ? item.errorMessage({
              messages: strings as any,
              value: values[item.id as keyof LeadFormState],
            })
          : {};
      }

      if (
        item.isValid &&
        (touched[item.id] || item.id === 'financeQuoteTcsChkBox') &&
        !item.isValid({ value: values[item.id as keyof LeadFormState], values, vehicle: vehicle })
      ) {
        newErrors[item.id] = item.errorMessage
          ? item.errorMessage({
              messages: strings as any,
              value: values[item.id as keyof LeadFormState],
            })
          : {};
      }
      if (
        item.isValid &&
        item.id === 'callBackInstantlyChkBox' &&
        !item.isValid({ value: values[item.id as keyof LeadFormState] }) &&
        !(values.callBackInstantlyChkBox || values.callBackDuringDayChkBox) &&
        errors[item.id] !== null
      ) {
        newErrors[item.id] = item.errorMessage
          ? item.errorMessage({
              messages: strings as any,
            })
          : null;
      }
      if (
        item.isValid &&
        item.id === 'callBackDuringDayChkBox' &&
        !item.isValid({ value: values[item.id as keyof LeadFormState] }) &&
        !(values.callBackInstantlyChkBox || values.callBackDuringDayChkBox) &&
        errors[item.id] !== null
      ) {
        newErrors[item.id] = item.errorMessage
          ? item.errorMessage({
              messages: strings as any,
            })
          : null;
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      return true;
    }

    return false;
  };

  return {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleKeyDown,
    inputRefs,
    handleSubmitValidation,
  };
};
