import { useEffect } from 'react';

import { LeadFormState, formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { finCalcInteractedWithAtom } from '@fincalc/Atoms/FinCalcInteractedWith.atom';
import { Dropdown } from '@gds/Dropdown/Dropdown';
import { Input } from '@gds/Input/Input';
import useBreakpoint from '@gds/Pagination/Hooks/UseBreakpoint.hook';
import { Textarea } from '@gds/Textarea/Textarea';
import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';
import { enableFormTracking } from '@snowplow/browser-plugin-form-tracking';
import { useAtomValue } from 'jotai';

import { leadFormIdAtom } from 'Atoms/App/Pdp/LeadFormId.atom';

import AutoCallbackPreference from 'Components/LeadForms/MessageDealer/AutomaticCallbackPreference/AutomaticCallbackPreference';

import { FieldsProps } from './FormFields.entity';

import styles from './FormFields.module.css';

export const FormFields = ({ fields, strings, fieldProps }: FieldsProps) => {
  const flags = useAtomValue(flagsAtom);
  const vehicle = useAtomValue(currentVehicleAtom)!;
  const leadType = useAtomValue(leadFormIdAtom);
  const values = useAtomValue(formStateAtom);
  const isFinCalcInteractedWith = useAtomValue(finCalcInteractedWithAtom);
  const {
    breakpoints: { isMobile, isTablet },
  } = useBreakpoint();

  const { touched, errors, handleChange, handleBlur, handleKeyDown, inputRefs } = fieldProps;
  const minDate = new Date();

  const trackableForms = ['message-dealer', 'request-callback'];
  const isExperimentEnabled =
    flags.messageFieldLeadformExperiment && trackableForms.includes(leadType);
  const isMsgLeadAutoCallbackExp =
    leadType === 'message-dealer' && flags.messageLeadAutomaticCallbackTest;
  useEffect(() => {
    if (trackableForms.includes(leadType)) {
      enableFormTracking({
        options: {
          events: ['focus_form' as any, 'change_form' as any, 'submit_form' as any],
        },
      });
    }
  }, [leadType, fields]);

  const getType = (id: string) => {
    if (id === 'email') return 'email';
    if (id === 'phone') return 'tel';
    return 'text';
  };

  return (
    <div className={styles.fieldGroup}>
      {fields.map(item => {
        const width = item.width?.({ flags, vehicle, values, isFinCalcInteractedWith });

        return (
          item?.type && (
            <div
              className={styles.field}
              data-width={width}
              data-id={item.id}
              key={`${leadType}-${item.id}`}
            >
              {item.type === 'input' && (
                <Input
                  key={item.id}
                  id={item.id}
                  dataTestId={item.dataTestId ?? item.id}
                  label={item.label && item.label(strings as any)}
                  autoComplete={item.autoComplete}
                  error={errors[item.id]}
                  fullWidth
                  onChange={e => handleChange(item.id, e.target.value)}
                  value={values[item.id as keyof LeadFormState] || ''}
                  onBlur={() => handleBlur(item.id)}
                  onKeyDown={event => handleKeyDown(item.id, event)}
                  ref={input => {
                    inputRefs.current[item.id] = input;
                  }}
                  required
                  type={getType(item.id)}
                />
              )}
              {item.type === 'date' && (
                <Input
                  label={item.label && item.label(strings as any)}
                  id={item.id}
                  onBlur={() => handleBlur(item.id)}
                  onChange={({ target: { value } }) => handleChange(item.id, value)}
                  value={values[item.id as keyof LeadFormState] || ''}
                  error={errors[item.id]}
                  fullWidth
                  type={'date' as unknown as 'text'}
                  min={minDate?.toISOString?.().split('T')[0]}
                />
              )}
              {item.type === 'select' && (
                <Dropdown
                  label={item.label && item.label(strings as any)}
                  dataTestId={item.dataTestId}
                  options={item.options ? item.options(strings, vehicle, values) : []}
                  onChange={e => handleChange(item.id, e)}
                  value={values[item.id as keyof LeadFormState] || ''}
                  className={styles.dropDown}
                  error={errors[item.id]}
                  onBlur={() => handleBlur(item.id)}
                />
              )}
              {item.type === 'textarea' && (
                <Textarea
                  value={values[item.id as keyof LeadFormState]}
                  label={item.label && item.label(strings as any)}
                  fullWidth
                  autoSize
                  maxLength={200}
                  error={touched[item.id] && errors[item.id]}
                  onChange={e => handleChange(item.id, e.target.value)}
                  onBlur={() => handleBlur(item.id)}
                  onKeyDown={event => handleKeyDown(item.id, event)}
                  hideCounterMobile
                  oneLiner={isMobile || isTablet}
                  className={styles.textarea}
                  isExperimentEnabled={isExperimentEnabled && !isMobile && !isTablet}
                  data-experiment-flag={isExperimentEnabled}
                  id={item.id}
                />
              )}
            </div>
          )
        );
      })}
      {/* This is a temporary soln to keep this here until we have a better way to handle this */}
      {isMsgLeadAutoCallbackExp && (
        <div data-test-id="auto-callback-preference">
          <AutoCallbackPreference
            values={values}
            handleChange={handleChange}
            isMsgLeadAutoCallbackExp={isMsgLeadAutoCallbackExp}
            errors={errors}
            strings={strings}
          />
        </div>
      )}
    </div>
  );
};
